export const STATUS = {
  VALID: 'VALID',
  INVALID_BALANCE: 'INVALID_BALANCE',
  NO_CALL_BALANCE: 'NO_CALL_BALANCE',
  NOT_ALLOWED: 'NOT_ALLOWED',
  BELOW_TABLE_LIMIT: 'BELOW_TABLE_LIMIT',
  OVER_TABLE_LIMIT: 'OVER_TABLE_LIMIT',
  BELOW_INDEX_LIMIT: 'BELOW_INDEX_LIMIT',
  OVER_INDEX_LIMIT: 'OVER_INDEX_LIMIT',
  BETTING_NOT_ALLOWED: 'BETTING_NOT_ALLOWED',
  ADJUST_BET_TO_BALANCE: 'ADJUST_BET_TO_BALANCE',
  ADJUST_BET_TO_INDEX_LIMIT: 'ADJUST_BET_TO_INDEX_LIMIT',
  ADJUST_BET_TO_TABLE_LIMIT: 'ADJUST_BET_TO_TABLE_LIMIT',
};

export const NOTIFICATIONS = {
  VALIDATION: 'VALIDATION',
};
