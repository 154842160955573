import { selectors, actions, } from '@ezugi/bootstrap';
import {
  mapObjIndexed, filter, prop, values, pick,
} from 'ramda';

import { currentBetsSelector, } from '../../../selectors/bets';
import { getTotalBet, } from '.';
import { SIDEBETS, MAIN_BETS, } from '../../../../constants/betTypes';

const { socketActions, notificationActions, betActions, } = actions;
const { userSelector, tableIdSelector, roundIdSelector, gameSelector, } = selectors;
export const buildParams = (bets, state, seed) => {
  const user = userSelector(state);

  return {
    ClientId: user.clientId,
    ClientIP: user.clientIp,
    CurrentPlayerToken: user.currentPlayerToken,
    MessageType: 'PlaceBet',
    destination: 'player',
    gameType: gameSelector(state).gameId,
    TableId: tableIdSelector(state).toString(),
    roundId: roundIdSelector(state),
    BetsList: bets,
    ...seed,
  };
};

const keepValidBets = filter(prop('valid'));

export const createBetsList = (bets) => {
  // Send bets
  const seed = mapObjIndexed(({ value, }) => value, bets);

  const betsList = pick(values(MAIN_BETS), seed);
  betsList.SideBets = pick(values(SIDEBETS), seed);

  return betsList;
};

export const createPlaceBetRequestPayload = (state) => {
  const currentBetsList = currentBetsSelector(state);
  const _actions = [];

  const currentBets = values(currentBetsList);

  if (currentBets.length) {
    const validBetsList = keepValidBets(currentBetsList);
    const totalBet = getTotalBet(validBetsList);

    if (currentBets.length > values(validBetsList).length) {
      // Remove invalid bets and update `TotalBet` and `BetHistory`
      _actions.push(notificationActions.notification.add({ message: 'notifications.bet_below_min_limits_removed', }));
      _actions.push(betActions.history.apply({ current: validBetsList, }));
      _actions.push(betActions.totalBet.set({ value: totalBet, }));
    }

    // Cache valid bets for `Rebet`
    _actions.push(betActions.bet.cache({ current: validBetsList, totalBet, }));

    // Send bets
    const betsList = createBetsList(validBetsList);

    const params = buildParams(betsList, state);
    _actions.push(socketActions.socket.send(params));
  }

  return _actions;
};

export const createCurrentBetRequestPayload = (state) => {
  const currentBetsList = currentBetsSelector(state);
  const betsList = createBetsList(currentBetsList);

  return buildParams(betsList, state, {
    destination: 'table',
    MessageType: 'CurrentBet',
  });
};
