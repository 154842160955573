import { createSelector, } from 'reselect';
import { prop, pathOr, } from 'ramda';
import { selectors, } from '@ezugi/bootstrap';

import * as BET_TYPES from '../../constants/betTypes';

const { betsSelector, } = selectors;

export const currentBetsSelector = createSelector(
  betsSelector,
  prop('current')
);

export const currentAnteBetSelector = createSelector(
  currentBetsSelector,
  pathOr(0, [ BET_TYPES.ANTE, 'value', ])
);

export const betsHistorySelector = createSelector(
  betsSelector,
  prop('history')
);

export const lastBetsSelector = createSelector(
  betsSelector,
  prop('last')
);
