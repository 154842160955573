import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';

const {
  roundActions: { round, },
  gameActions: { game, },
} = actions;

export default function handlePlaceYourBets() {
  return of(
    round.set({
      firstCardHands: {},
    }),
    game.set({
      gameResults: {},
    })
  );
}
