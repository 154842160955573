import { of, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';

const {
  betActions: { bet, },
  notificationActions: { notification, },
} = actions;

export default function handleAutofold() {
  return of(bet.fold(), notification.add({ message: 'notifications.auto_fold_message', }));
}
